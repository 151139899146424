import { useEffect, useState } from "react";
import styles from "./PublishPage.module.css";
import { Login } from "../../components/PublishPageComponents/Login";
import AddProtocolPage from "./AddProtocol";
import { AddBlockchain } from "../../components/PublishPageComponents/AddBlockchain";
import { adminLoginData, userData } from "../../utils/severRequestTypes";
import { protocolPreview } from "../../utils/reportDataTypes";
import { getReportPreviewsUnderReviewURL } from "../../api";
import { sendRequest } from "../../utils/apiRequest";
import { AddAuditCompany } from "../../components/PublishPageComponents/AddAuditCompany";
import { Link } from "react-router-dom";

interface IPublishPage {
  user: userData | null;
  setCurrentProtocolID: (addProtocolActive: number) => void;
}

const PublishPage: React.FC<IPublishPage> = ({
  user,
  setCurrentProtocolID,
}) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(user !== null);
  const [addProtocolPageDisplay, setAddProtocolPageDisplay] = useState(false);
  const [addAuditCompanyPageDisplay, setAddAuditCompanyPageDisplay] =
    useState(false);
  const [addBlockChainPageDisplay, setAddBlockChainPageDisplay] =
    useState(false);
  const [loginData, setLoginData] = useState<adminLoginData>({
    id: 0,
    name: "",
    password: "",
  });
  const [allprotocols, setAllprotocols] = useState<protocolPreview[]>([
    { rid: 0, name: "", logoURL: "", auditAmount: -1, score: -1 },
  ]);
  const [currentProtocol, setCurrentProtocol] = useState<protocolPreview>({
    rid: 0,
    name: "",
    logoURL: "",
    auditAmount: -1,
    score: -1,
  });

  const sendGetProductsRequest = async () => {
    await sendRequest(
      getReportPreviewsUnderReviewURL,
      "Something went wrong while getting past reports!",
      undefined,
      undefined,
      setAllprotocols
    );
  };

  useEffect(() => {
    const fetchProtocolPreviews = async () => {
      await sendGetProductsRequest();
    };
    fetchProtocolPreviews();
  }, []);

  const setCurrentProtocolAndOpenPage = (protocolPreview: protocolPreview) => {
    setAddProtocolPageDisplay(true);
    setCurrentProtocol({ ...protocolPreview });
  };

  const setAddProtocolPageFalse = () => {
    setAddProtocolPageDisplay(false);
    setCurrentProtocol({
      rid: -1,
      name: "",
      logoURL: "",
      auditAmount: -1,
      score: -1,
    });
  };

  if (loggedIn) {
    if (
      addProtocolPageDisplay &&
      !addAuditCompanyPageDisplay &&
      !addBlockChainPageDisplay
    ) {
      return (
        <AddProtocolPage
            setAddProtocolActive={setAddProtocolPageFalse}
            protocolPreview={currentProtocol}
            adminLoginData={loginData}
          />
      );
    } else if (
      addAuditCompanyPageDisplay &&
      !addBlockChainPageDisplay &&
      !addProtocolPageDisplay
    ) {
      return (
        <div className={styles.container}>
          <AddAuditCompany
            setAuditCompanyIsActive={setAddAuditCompanyPageDisplay}
          />
        </div>
      );
    } else if (addBlockChainPageDisplay) {
      return (
        <div className={styles.container}>
          <AddBlockchain
            adminLoginData={loginData}
            setAddBlockchainActive={setAddBlockChainPageDisplay}
          />
        </div>
      );
    } else {
      return (
        <div className={styles.buttonContainer}>
          <div className={styles.containerInner} style={{ width: "30%" }}>
            <h3>Create new Report</h3>
            <span>Create a new report for a protocol not added to SCAS yet</span>
            <button
              className={styles.saveButton}
              onClick={() => setAddProtocolPageDisplay(!addProtocolPageDisplay)}
            >
              {" "}
              Add Protocol{" "}
            </button>
            <button
              className={styles.saveButton}
              onClick={() =>
                setAddAuditCompanyPageDisplay(!addAuditCompanyPageDisplay)
              }
            >
              {" "}
              Add Audit Company{" "}
            </button>
            <button
              className={styles.saveButton}
              onClick={() =>
                setAddBlockChainPageDisplay(!addBlockChainPageDisplay)
              }
            >
              {" "}
              Add Blockchain{" "}
            </button>
          </div>
          <div className={styles.containerInner} style={{ width: "70%" }}>
            <h3>Edit Protocol Entries</h3>
            <span>Following reports are not published yet and cant be viewed on the main page.</span>
            <ul className={styles.ReportList}>
              {allprotocols.map((protocol) => {
                return (
                  <li className={styles.row} key={protocol.rid}>
                    <h4 className={styles.IDtext}>{protocol.rid}</h4>
                    <img
                      className={styles.logo}
                      src={protocol.logoURL}
                      alt=""
                    />
                    <p className={styles.text}>{protocol.name}</p>
                    <p className={styles.text} style={{color:"rgba(255, 159, 64, 1)"}}>Under Review</p>
                    {loginData.name.length > 1 ? 
                    <button
                    className={styles.editButton}

                    >
                    Publish
                    </button>
                    : ""}
                    <Link
                      to={`/protocol/${protocol.name}`}
                      onClick={() => setCurrentProtocolID(protocol.rid)}
                    >
                      View
                    </Link>
                    <button
                      className={styles.editButton}
                      onClick={() => setCurrentProtocolAndOpenPage(protocol)}
                    >
                      {" "}
                      Edit{" "}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    }
  } else {
    return <Login setLoginData={setLoginData} setLoggedIn={setLoggedIn} />;
  }
};

export default PublishPage;
