import { useState } from "react";
import styles from "./AddProtocol.module.css";
import { BasicInfo } from "../../../components/PublishPageComponents/BasicInfo";
import CodeComplexity from "../../../components/PublishPageComponents/CodeComplexity";
import Centralization from "../../../components/PublishPageComponents/Centralization";
import { protocolPreview } from "../../../utils/reportDataTypes";
import { SecInfra } from "../../../components/PublishPageComponents/SecInfra";
import { adminLoginData } from "../../../utils/severRequestTypes";
import ScoreCalculation from "../../../components/PublishPageComponents/ScoreCalculation";
import AdvancedShowMoreButton from "../../../components/PublishPageComponents/AdvancedShowMoreButton";
import SideMenuPublishTool from "../../../components/PublishPageComponents/SideMenu";

interface IAddProtocolPage {
  adminLoginData: adminLoginData;
  protocolPreview: protocolPreview;
  setAddProtocolActive: (addProtocolActive: boolean) => void;
}
const AddProtocolPage: React.FC<IAddProtocolPage> = ({
  protocolPreview,
  adminLoginData,
  setAddProtocolActive,
}) => {
  const [currentProtocol, setCurrentProtocol] =
    useState<protocolPreview>(protocolPreview);
  const [showBasicInfo, setShowBasicInfo] = useState<boolean>(false);
  const [progressBasicInfo, setProgressBasicInfo] = useState<number[]>([0,6]);

  const [showSecInfra, setShowSecInfra] = useState<boolean>(false);
  const [progressSecInfra, setProgressSecInfra] = useState<number[]>([0,4]);

  const [showCodeComp, setShowCodeComp] = useState<boolean>(false);
  const [progressCodeComp, setProgressCodeComp] = useState<number[]>([0,4]);

  const [showCentraInfo, setShowCentraInfo] = useState<boolean>(false);
  const [progressCentraInfo, setProgressCentraInfo] = useState<number[]>([0,5]);

  const [showScoreCalc, setShowScoreCalc] = useState<boolean>(false);
  const [progressScoreCalc, setProgressScoreCalc] = useState<number[]>([0,2]);


  const setCurrentProtocolID = (i: number) => {
    setCurrentProtocol({
      ...currentProtocol,
      rid: i,
    });
  };

  return (
    <>
      <div className={styles.container} id="adminContainer">
        <SideMenuPublishTool protocolPreview={protocolPreview} setAddProtocolActive={setAddProtocolActive}/>
        <div className={styles.contentContainer}>
          <div className={styles.header}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap:"10px",
                marginLeft:"14rem"
              }}
            >
              <b className={styles.RoundNumber}>
              1
              </b>
              
              <b className={styles.headerText}>
                Edit Report
              </b>
              <div className={styles.dividerLine}/>
              <b className={styles.RoundNumber} style={{color:"#999999",borderColor:"#999999"}}>
              2
              </b>
              <b className={styles.headerText} style={{color:"#999999"}}>
                Report is Reviewed
              </b>
              <div className={styles.dividerLine}/>
              <b className={styles.RoundNumber} style={{color:"#999999",borderColor:"#999999"}}>
              3
              </b>
              <b className={styles.headerText} style={{color:"#999999"}}>
                Published
              </b>
            </div>
          </div>
          <div className={styles.contentContainerWithOutHeader}>
          <AdvancedShowMoreButton
            onClick={() => setShowBasicInfo(!showBasicInfo)}
            text={"Add Basic Information"}
            showMore={showBasicInfo}
            progress={progressBasicInfo}
          />
          <div
            className={styles.sectionContainer}
            style={{ height: showBasicInfo ? `35rem` : "0rem"}}
          >
            <BasicInfo
              adminLoginData={adminLoginData}
              protocolId={currentProtocol.rid}
              setProtocolID={setCurrentProtocolID}
              setProgress={setProgressBasicInfo}
            />
          </div>
          <AdvancedShowMoreButton
            onClick={() => setShowSecInfra(!showSecInfra)}
            text={"Add Security Infrastructure Information"}
            showMore={showSecInfra}
            progress={progressSecInfra}
          />
          <div
            className={styles.sectionContainer}
            style={{ height: showSecInfra ? "29rem" : "0rem" }}
          >
            <SecInfra
              adminLoginData={adminLoginData}
              protocolReportID={currentProtocol.rid}
              setProgress={setProgressSecInfra}
            />
          </div>
          <AdvancedShowMoreButton
            onClick={() => setShowCodeComp(!showCodeComp)}
            text={"Add Code Base Information"}
            showMore={showCodeComp}
            progress={progressCodeComp}
          />
          <div
            className={styles.sectionContainer}
            style={{ height: showCodeComp ? "30rem" : "0rem" }}
          >
            <CodeComplexity
              adminLoginData={adminLoginData}
              protocolReportID={currentProtocol.rid}
              setProgress={setProgressCodeComp}
            />
          </div>
          <AdvancedShowMoreButton
            onClick={() => setShowCentraInfo(!showCentraInfo)}
            text={"Add Centralization Information"}
            showMore={showCentraInfo}
            progress={progressCentraInfo}
          />
          <div
            className={styles.sectionContainer}
            style={{ height: showCentraInfo ? "30rem" : "0rem" }}
          >
            <Centralization
              adminLoginData={adminLoginData}
              protocolId={currentProtocol.rid}
              setProgress={setProgressCentraInfo}
            />
          </div>
          <AdvancedShowMoreButton
            onClick={() => setShowScoreCalc(!showScoreCalc)}
            text={"Calculate Score"}
            showMore={showScoreCalc}
            progress={progressScoreCalc}
          />
          <div
            className={styles.sectionContainer}
            style={{ height: showScoreCalc ? "10rem" : "0rem" }}
          >
            <ScoreCalculation
              adminLoginData={adminLoginData}
              protocolReportID={currentProtocol.rid}
              setProgress={setProgressScoreCalc}
            />
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProtocolPage;
