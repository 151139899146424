import { useState } from "react";
import PurpleButton from "../../Reusable/PurpleButton";
import ProgressBar from "../ProgressBar";
import styles from "../RegisterPopUp.module.css";
import { sendRequest } from "../../../utils/apiRequest";
import { verifyCodeURL } from "../../../api";

interface IConfirmationCodePage{
    handelShowFinish: () => void;
    progress: number,
    isProSignUp: boolean,
    email: string
}

function isValidCode(code:string) {
    var regex = /^\d{6}$|^\d{3} \d{3}$/;
    return regex.test(code);
}

const ConfirmationCodePage:React.FC<IConfirmationCodePage> = ({email,progress,isProSignUp,handelShowFinish}) =>{
    const [confirmCodeError,setConfirmCodeError]=useState<boolean>(false);
    const [confirmationValid,setConfirmationValid]=useState<boolean>(false);
    const [confirmCode,setConfirmCode]=useState<string>("")

    const handleConfirmationCodeChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setConfirmCodeError(!isValidCode(e.target.value))
        setConfirmCode(e.target.value)
    }

    const handleConfirm=async ()=>{
        const request = {email:email,code:confirmCode}
        const success = await sendRequest(verifyCodeURL,"Error Verify!","Code is valid!",request);
        setConfirmationValid(success);
    }

    return(
        <div className={styles.container}>
                <h3 className={styles.loginTitle}>Confirm Email</h3>
                <div className={styles.containerInner}>
                    <div className={styles.inputGroup}>
                    <label className={styles.loginLable}>Enter your confirmation code send to {email}</label>
                    <input value={confirmCode} onChange={handleConfirmationCodeChange} className={styles.loginInput} type="text" placeholder='000 000' />
                    {confirmCodeError && <span className={styles.errorMsg}>Invalid code</span>}
                </div>
                    
                    <div className={styles.buttons}>
                        <PurpleButton disabled={!isValidCode(confirmCode)} fixedsize={true} text="Confirm" onClick={handleConfirm}/>
                    </div>
                    
                </div>
                <ProgressBar completed={progress}/>
                <div className={styles.buttons}>
                <PurpleButton fixedsize={true} disabled={!confirmationValid} text={isProSignUp ? "Next" : "Finish"} onClick={handelShowFinish} />
                </div>
            </div>
    )
}

export default ConfirmationCodePage