import styles from './BasicList.module.css'
import { IoIosRemoveCircle } from 'react-icons/io'
import { inValidBorder, validBorder } from '../../../utils/adminUtils'
import {BasicRadioInput,IRadioButton} from '../BasicRadioInput';
import BasicSelect from '../BasicSelect';


interface inputItem{
    type: 'input';
    property:string;
    onChange:(i: number)=>(e: React.ChangeEvent<HTMLInputElement>) => void;
    title:string;
    placeholder:string;
    borderIsValid:Array<boolean>;

}

interface textareaItem{
    type: 'textarea';
    property:string;
    onChange:(i: number)=>(e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    title:string;
    placeholder:string;
    borderIsValid:Array<boolean>;
}

interface radioItem{
    title:string;
    type: 'radio';
    onChange:(i: number)=>(e: React.ChangeEvent<HTMLInputElement>) => void;
    buttons:Array<IRadioButton>
    property:string;
}
interface selectItem{
    title:string;
    type: 'select';
    property?: string;
    onChange:(i: number)=>(e: React.ChangeEvent<HTMLSelectElement>) => void;
    options:Array<any>;
    value:string;
    display:string;
}

interface IBasicList{
    title?:string;
    handleRemove:(i:number)=>void;  
    handleAdd:()=>void;
    list:Array<any>;
    height:number;
    items:Array<inputItem|textareaItem|radioItem|selectItem>;
    gap?:number;
    addButtonText?:string
}


const BasicList:React.FC<IBasicList>=({title,handleRemove,handleAdd,list,height,items,gap,addButtonText})=>{
    return(
        <div className={styles.container}>
            <div className={styles.containerHeader}>
                <h3>{title}</h3>
            </div>

            <div className={styles.ListOfForms} style={{gap:`${gap}px`,height:`${height}px`}}>
                {list.map((n,i)=>{
                    return (
                    <div key={i} className={styles.Form}> 
                        <div className={styles.ListOfInputs}>
                            {items.map((item,i2)=>{
                                return (
                                    <div className={styles.Input} key={i2}> 
                                        {item.title && <div>{item.title}</div>}
                                        {item.type === "textarea" && <textarea value={n[item.property]} onChange={item.onChange(i)} placeholder={item.placeholder} style={(item.borderIsValid.length === 0 || item.borderIsValid[i]) ? validBorder:inValidBorder} />}
                                        {item.type === "input" && <input value={n[item.property]} onChange={item.onChange(i)} placeholder={item.placeholder} style={(item.borderIsValid.length === 0 || item.borderIsValid[i]) ? validBorder:inValidBorder}/>}
                                        {item.type === "radio" && <BasicRadioInput value={n[item.property]} buttons={item.buttons} onChange={item.onChange(i)}/>}
                                        {item.type === "select" && <BasicSelect display={item.display} options={item.options} value={item.value} onChange={item.onChange(i)} curValue={item.property != null ? n[item.property] : n}/>}

                                    </div>
                                )
                            })}
                        </div>
                        <IoIosRemoveCircle onClick={()=>handleRemove(i)}/>
                    </div>
                )})}
            </div>
            <button className={styles.AddMoreButton} onClick={()=>handleAdd()}> + Add {addButtonText}</button>
        </div>
    )}

export default BasicList
