import styles from "./ProtocolPage.module.css";
import InfoHeader from "../../components/ProtocolPageComponents/InfoHeader";
import SideMenu from "../../components/ProtocolPageComponents/SideMenu";
import SecInfra from "../../components/ProtocolPageComponents/SecInfra";
import Pastaudits from "../../components/ProtocolPageComponents/PastAudits";
import CodeComplexity from "../../components/ProtocolPageComponents/CodeComplexity";
import Centralization from "../../components/ProtocolPageComponents/Centralization";
import CrossProtocolExposure from "../../components/ProtocolPageComponents/CrossProtocolExposure";
import Recomendations from "../../components/ProtocolPageComponents/Recomendations";
//Only for dev rn, will be replaced by API call
import { tmpAuditCompanie, tmpProtocolReport } from "../../utils/templateProtocolData";

import { useEffect, useRef, useState } from "react";
import Disclaimer from "../../components/ProtocolPageComponents/Disclaimer";
import { darkTheme } from "../../utils/theme";
import { protocolData,auditCompanies } from "../../utils/reportDataTypes";
import { getCompanyByIdArrayURL, getReportDataURL } from "../../api";
import { sendRequest } from "../../utils/apiRequest";
import { userData } from "../../utils/severRequestTypes";
import { MdErrorOutline } from "react-icons/md";

interface IProtocolPage {
  currentTheme?: typeof darkTheme;
  currentProtocolID?: number;
  user?:userData | null;
}

const ProtocolPage: React.FC<IProtocolPage> = ({
  currentProtocolID,
  currentTheme,
  user
}) => {
  const secInfraRef = useRef(null);
  const PastauditsRef = useRef(null);
  const CodeComplexityRef = useRef(null);
  const CentralizationRef = useRef(null);
  const CrossProtocolExposureRef = useRef(null);
  const RecomendationsRef = useRef(null);

  const [protocol, setProtocol] = useState<protocolData>(tmpProtocolReport);
  const [auditCompanies, setAuditCompanies] = useState<auditCompanies[]>([
    tmpAuditCompanie,
  ]);
  
  useEffect(() => {
    const fetchProtocolReport = async () => {
        const id = [currentProtocolID];
        await sendRequest(
          getReportDataURL,
          "Error while getting report",
          undefined,
          id,
          setProtocol
        );

    };
    
    if(user) fetchProtocolReport();
  }, [currentProtocolID,user]);

  useEffect(() => {
    const fetchAuditCompanies = async () => {
        await sendRequest(
          getCompanyByIdArrayURL,
          "Something went wrong while getting audit companies!",
          undefined,
          protocol.secInfra.auditCompanyIDs,
          setAuditCompanies
        );
    };
    
    if(protocol !== tmpProtocolReport && protocol.secInfra.auditCompanyIDs.length > 0) fetchAuditCompanies();
  }, [protocol]);

  if(!user){
    return(
          <div className={styles.errorBoxContainer}>
            <div className={styles.ErrorBox}>
              <MdErrorOutline className={styles.Icon} size={25}/>
              <p>Please Login to view Protocol data</p>
            </div>
          </div>
    );
  }
  return (
    <div className="about">
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <SideMenu
            refs={[
              secInfraRef,
              PastauditsRef,
              CodeComplexityRef,
              CentralizationRef,
              CrossProtocolExposureRef,
              RecomendationsRef,
            ]}
          />
          <div className={styles.protocolContent}>
            <InfoHeader protocolData={protocol} />
            <SecInfra protocolData={protocol} ref={secInfraRef} auditCompanies={auditCompanies}/>
            <Pastaudits
              currentTheme={currentTheme}
              codeData={protocol.codeComplexity}
              pastAudits={protocol.pastAudits}
              ref={PastauditsRef}
              auditCompanies={auditCompanies}
            />
            <CodeComplexity
              currentTheme={currentTheme}
              codeComplexity={protocol.codeComplexity}
              ref={CodeComplexityRef}
            />
            <Centralization protocolData={protocol} ref={CentralizationRef} />
            <CrossProtocolExposure
              crossProtocolExposure={protocol.crossProtocolExposure}
              ref={CrossProtocolExposureRef}
            />
            <Recomendations
              recommendations={protocol.recommendations}
              ref={RecomendationsRef}
            />
            <Disclaimer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtocolPage;
