import PurpleButton from "../../Reusable/PurpleButton";
import ProgressBar from "../ProgressBar";
import styles from "../RegisterPopUp.module.css";

const usdcLink = "https://polygonscan.com/token/0x3c499c542cef5e3811e1192ce70d8cc03d5c3359";

interface IPaymentPage{
    handelShowFinish: () => void;
    progress: number,
    wallet: string,
}

const PaymentPage:React.FC<IPaymentPage> = ({progress,wallet,handelShowFinish}) =>{
    return(
        <div className={styles.container}>
        <h3 className={styles.loginTitle}>Purchase 6 Month SCAS Pro</h3>
        <div className={styles.containerInner}>
        <div className={styles.description}>
            <p>On purchase Metamask will ask you to transfer 499 <a href={usdcLink}>USDC</a> to SCAS. 
                    You will have to sign the transaction and wait for it to be mined. </p>
            </div>

            <p className={styles.greyText}>If you need help or your transfer went trough but you get any error, please contact our support</p>
            
        </div>
        
        <ProgressBar completed={progress}/>
        <div className={styles.buttons}>
            <PurpleButton fixedsize={true} disabled={wallet.length < 2} text="Purchase" onClick={handelShowFinish} />
        </div>
        
        </div>
    )
}

export default PaymentPage