import styles from "./AccountPage.module.css";
import BasicInput from "../../components/Reusable/BasicInput";
import { useState } from "react";
import PurpleButton from "../../components/Reusable/PurpleButton";
import { MdGppGood } from "react-icons/md";
import { loginURL, ratingDocsURL, verifyCodeURL } from "../../api";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { loginRequest, userData } from "../../utils/severRequestTypes";
import { sendRequest } from "../../utils/apiRequest";
import ProgressBar from "../../components/RegisterPopUp/ProgressBar";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/textformUtils";

interface IAccountPage {
  user: userData | null;
  setUser: (user: userData | null) => void;
}

const AccountPage: React.FC<IAccountPage> = ({ user, setUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifyCode, setVerifyCode] = useState("");

  const [showLogin, setShowLogin] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  const [showAPIKey, setShowAPIKey] = useState(false);

  const getAPIKey = (show: boolean) => {
    if (show) {
      return <>{user?.apiData.key}</>;
    }
    const key = user?.apiData.key.replace(/./g, "*");
    return <>{key}</>;
  };

  const handleRegisterChange = async () => {
    const request: loginRequest = { email: email, pw: password };
    const response = await sendRequest(
      loginURL,
      "Error on Login",
      undefined,
      request,
      setUser
    );
    
    if (!response) return;
    setShowLogin((prev) => !prev);
  };

  const handleConfirm=async ()=>{
    const request = {email:email,code:verifyCode}
    const success = await sendRequest(verifyCodeURL,"Error Verify!","Code is valid!",request);
    setShowVerify(!success);
  }
  const handelEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handelVerifyCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerifyCode(e.target.value);
  };

  if(showVerify){
    return (
      <div className={styles.payment}>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <h3>Verify Email</h3>
          <BasicInput
            title={"Code"}
            placeholder="000 000"
            onChange={handelVerifyCodeChange}
            redBorder={false}
          />
          <div className={styles.description}>
            <p>
              A Verification email has been send on account creation. Check your spam folder
            </p>
          </div>
          <div className={styles.buttonContainer}>
            <PurpleButton
              text="Verify"
              onClick={handleConfirm}
              fontSize="14px"
            />
          </div>
        </div>
      </div>
    </div>
    );
  }
  if (showLogin && user === null) {
    return (
      <div className={styles.payment}>
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <h3>Login</h3>
            <BasicInput
              title={"Email"}
              placeholder="example@gmail.com"
              onChange={handelEmailChange}
              redBorder={false}
            />
            <BasicInput
              title={"Password"}
              placeholder="****"
              type="password"
              onChange={handlePasswordChange}
              redBorder={false}
            />
            <div className={styles.description}>
              <p>
                Forgot your password: Reset
              </p>
            </div>
            <div className={styles.buttonContainer}>
              <PurpleButton
                text="Login"
                onClick={handleRegisterChange}
                fontSize="14px"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.payment}>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <h3>Welcome!</h3>
          <div className={styles.description}>
            <p>
              View how many API calls you have left, upgrade to pro and more{" "}
            </p>
          </div>
          <h4>Email</h4>
          <p>{user?.email} </p>
          <h4>API key
          {showAPIKey ? (
              <FaEyeSlash onClick={() => setShowAPIKey((prev) => !prev)} />
            ) : (
              <FaEye onClick={() => setShowAPIKey((prev) => !prev)} />
            )}
          </h4>
          <p>
            <p className={styles.apiKeyText}>{getAPIKey(showAPIKey)}{" "}</p>
          </p>
          <h4>Monthly calls used</h4>
          <div className={styles.progressBarContainer}>
          <ProgressBar completed={((user?.apiData.calls ?? 100) / 500) * 100} />
          </div>
          <h4>Next Billig Period</h4>
          <p>{formatDate(user?.apiData.nextPeriod ?? "")}</p>
          <div className={styles.buttonContainer}>
            <Link to="../publish">
              <PurpleButton
                text="Submit Reports"
                onClick={() => {}}
                fontSize="14px"
              />
            </Link>
            <Link to={ratingDocsURL}>
              <PurpleButton
                text="View API Docs"
                onClick={() => {}}
                fontSize="14px"
              />
            </Link>
            <PurpleButton
                text="Logout"
                onClick={() => setUser(null)}
                fontSize="14px"
              />
          </div>
        </div>
        <div>
          <div style={{ height: "5rem" }} className={styles.InnerContainer}>
            <div className={styles.Icon}>
              <MdGppGood size={35} />
            </div>
            <p>Submit 5 reports to SCAS to gain 3 month access to SCAS Pro</p>
          </div>
          <div style={{ height: "5rem" }} className={styles.InnerContainer}>
            <div className={styles.Icon}>
              <MdGppGood size={35} />
            </div>
            <p>Buy SCAS Pro for 6 months</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
