import { Link, NavLink, useLocation } from "react-router-dom";
import styles from './Navbar.module.css';
import { IoList } from "react-icons/io5";

interface INavbar{
    user:Object|null
}

const Navbar:React.FC<INavbar> = ({user}) => {
    const location = useLocation();
    const linkStyling:boolean = location.pathname.split("/")[1] === 'protocol' 

    return (
        <nav className={styles.navbar}>
            <div className={styles.container}>
                <div className={styles.logo} >
                    <Link className={linkStyling ? styles.logoLinkFixed : styles.logoLink} to={`/`}>SCAuditStudio</Link>
                </div>
                
                <ul className={styles.links}>
                    <li>
                        <NavLink key={1} to="/auditCompanies" className={({ isActive }) => isActive ? styles.NavBarLinkActive : styles.NavBarLink}>
                            <IoList/>
                            <span > Audit Companies </span>
                        </NavLink>

                        <NavLink key={2} to="/all-protocols" className={({ isActive }) => isActive ? styles.NavBarLinkActive : styles.NavBarLink}>
                            <IoList/>
                            <span >Protocols</span>
                        </NavLink>

                        <NavLink to="/account" 
                            className={({ isActive }) => isActive ? styles.LoginButtonActive : styles.LoginButton}>
                                {user
                                    ?<p>Account</p>
                                    :<p>Login</p>
                                }
                        </NavLink>
                        {user ? "" :
                        <NavLink to="/join-beta" 
                            className={({ isActive }) => isActive ? styles.LoginButtonActive : styles.LoginButton}>
                                <p>Register</p>
                        </NavLink>
                        }
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar