import { FaFileCode } from "react-icons/fa6";
import styles from "./VulnerbilitesList.module.css";
import { vulReport } from "../../../../utils/reportDataTypes";

interface IVulnerabilitiesList {
  totalReports: vulReport[];
}
function getSeverityColor(severity: string) {
  if (severity.toLowerCase() === "high") {
    return "rgba(255, 99, 132, 0.2)";
  } else if (severity.toLowerCase() === "medium") {
    return "rgba(255, 159, 64, 0.2)";
  } else if (severity.toLowerCase() === "low") {
    return "rgba(255, 206, 86, 0.2)";
  } else {
    return "rgba(75, 192, 192, 0.2)";
  }
}
function getSeverityBorderColor(severity: string) {
  if (severity.toLowerCase() === "high") {
    return "rgba(255, 99, 132, 1)";
  } else if (severity.toLowerCase() === "medium") {
    return "rgba(255, 159, 64, 1)";
  } else if (severity.toLowerCase() === "low") {
    return "rgba(255, 206, 86, 1)";
  } else {
    return "rgba(75, 192, 192, 1)";
  }
}
export const VulnerabilitiesList: React.FC<IVulnerabilitiesList> = ({
  totalReports,
}) => {
  return (
    <>
      <h3>Vulnerabilities reported in past audits</h3>
      <ul className={styles.vulReportList}>
        <li className={styles.stickyRow}>
          <div className={styles.vulLevelHeader}> Severity </div>
          <div className={styles.vulLevelHeader}> Type </div>
          <div className={styles.vulInfo}> Location </div>
          <div className={styles.linkIcon}> Link </div>
        </li>
        {totalReports.map((audit, i) => {
          return (
            <li
              key={i}
              style={
                i !== totalReports.length - 1
                  ? {
                      borderBottom:
                        "1px solid var(--ProtocolPage-border-color)",
                      display: "flex",
                    }
                  : { display: "flex" }
              }
            >
              <div
                style={{
                  background: getSeverityColor(audit.severity),
                  borderColor: getSeverityBorderColor(audit.severity),
                }}
                className={styles.vulLevel}
              >
                {audit.severity}
              </div>
              <div className={styles.vulLevelHeader}>
                {audit.issueType
                  .substring(0, audit.issueType.indexOf(","))
                  .replaceAll("u0022", "")
                  .replaceAll("[", "")}{" "}
              </div>
              <div className={styles.vulInfo}>{audit.file}</div>
              <div className={styles.linkIcon}>
                {" "}
                <a href={audit.link}>
                  View Report <FaFileCode />
                </a>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};
