import styles from "./AuditCompaniesPage.module.css";
import { getAllAuditCompaniesURL } from "../../api";
import { useEffect, useState } from "react";
import {
  auditCompanies,
  auditCompaniesType,
} from "../../utils/reportDataTypes";
import SearchBar from "../../components/Reusable/SearchBar";
import { getType } from "../../utils/textformUtils";
import { sendRequest } from "../../utils/apiRequest";

const AuditCompaniesPage = () => {
  const [allAuditCompanies, setAllAuditCompanies] = useState<auditCompanies[]>([
    {
      name: "",
      link: "",
      logoURL: "",
      type: auditCompaniesType.ContestPlatform,
      id: 0,
    },
  ]);
  const [auditCompanySearch, setAuditCompanySearch] = useState("");
  const [buttonState, setButtonState] = useState(1);

  const handleAuditCompanySearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAuditCompanySearch(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      await sendRequest(
        getAllAuditCompaniesURL,
        "Error while getting companies",
        undefined,
        undefined,
        setAllAuditCompanies
      );
    };
    fetchData();
  }, []);

  const filteredAuditCompanies =
    auditCompanySearch === ""
      ? allAuditCompanies.slice((buttonState - 1) * 50, buttonState * 50)
      : allAuditCompanies.filter((company) =>
          company.name
            .toLowerCase()
            .startsWith(auditCompanySearch.toLowerCase())
        );

  const handleButtonClick = (i: number) => {
    setAuditCompanySearch("");
    setButtonState(i);
  };
  return (
    <div className={styles.publications}>
      <div className={styles.container}>
        <div className={styles.reportContainer}>
          <h3>Smart Contract Audit Companies </h3>
          <p className={styles.infoText}>
            A currated list of the most important audit companies known to SCAS
          </p>
          <SearchBar
            value={auditCompanySearch}
            onChange={handleAuditCompanySearchChange}
            placeholder="Search for audit Company"
          />

          <ul className={styles.CompanieList}>
            <li className={styles.row}>
              <p>Company</p>
              <p>Company Website</p>
              <p className={styles.companyType}>Company Type</p>
            </li>
            {filteredAuditCompanies.map((company) => (company.name !== "Other" ?
              <li key={company.name} className={styles.row}>
                <div className={styles.rowNameContainer}>
                  <img alt="" src={company.logoURL} className={styles.image} />
                  <p>{company.name}</p>
                </div>
                <a href={company.link}>Website</a>
                <p className={styles.companyType}>{getType(company.type)}</p>
              </li>
              : ""))}
          </ul>
          {auditCompanySearch === "" && (
            <div className={styles.buttonsContainer}>
              {Array.from(
                {
                  length:
                    Math.floor(
                      (Math.max(0, allAuditCompanies.length) - 1) / 50
                    ) + 1,
                },
                (value, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => handleButtonClick(index + 1)}
                      style={
                        buttonState === index + 1 ? { color: "#6E5EE2" } : {}
                      }
                    >
                      {index + 1}
                    </button>
                  );
                }
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuditCompaniesPage;
