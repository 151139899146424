interface IProgressBar {
    completed: number;
}
const ProgressBar: React.FC<IProgressBar> = ({completed}) => {
  
    const containerStyles = {
      height: 10,
      width: '100%',
      backgroundColor: "#e0e0de",
      borderRadius: 50,
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      borderRadius: 'inherit',
      backgroundColor: "#6E5EE2",
      transition: 'all 0.3s ease-in-out',
    }
  
    const labelStyles = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles} />
        </div>
      </div>
    );
  };
  
  export default ProgressBar;