import styles from './RatingBar.module.css';
import { setColorFromSecScore } from '../../utils/colorFromSecScore';
interface IRatingBar{
    rating:number,
    color1:string,
    color2:string,
    color3:string,
}


function numToRating(num:number){
    const ratings = ["AAA", "AA", "A", "BBB", "BB", "B", "CCC", "CC"];
    for (let i = 90; i >= 20; i -= 10) {
      if (num >= i) {
        return ratings[(90 - i) / 10];
      }
    }
    return "C";
  }

const RatingBar:React.FC<IRatingBar> = ({rating,color1,color2,color3}) =>{
    return(
        <div className={styles.holder} style={{background:setColorFromSecScore(rating,color1,color2,color3)}}>
        {numToRating(rating)}
        </div>
    )
}

export default RatingBar