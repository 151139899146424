import styles from "./AddAuditCompany.module.css";
import {
  auditCompaniesType,
  auditCompanies,
} from "../../../utils/reportDataTypes";
import { useState } from "react";
import BasicInput from "../../Reusable/BasicInput";
import { writeAuditCompanieURL } from "../../../api";
import { BasicRadioInput } from "../../Reusable/BasicRadioInput";
import { tmpAuditCompanie } from "../../../utils/templateProtocolData";
import { sendRequest } from "../../../utils/apiRequest";

interface IAddAuditCompany {
    setAuditCompanyIsActive: (addAuditCompanyActive: boolean) => void;
}

export const AddAuditCompany: React.FC<IAddAuditCompany> = ({setAuditCompanyIsActive}) => {
  const [auditCompany, setAuditCompany] =
    useState<auditCompanies>(tmpAuditCompanie);

  const sendPublishReportDataRequest = async (request: auditCompanies) => {
    await sendRequest(
      writeAuditCompanieURL,
      "Error adding auditCompany",
      "Added company!",
      request
    );
  };

  const handelAuditCompanyNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let tmpAuditCompany: auditCompanies = auditCompany;
    tmpAuditCompany.name = e.target.value;
    setAuditCompany({ ...tmpAuditCompany });
  };

  const handelAuditCompanyURLChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let tmpAuditCompany: auditCompanies = auditCompany;
    tmpAuditCompany.link = e.target.value;
    setAuditCompany({ ...tmpAuditCompany });
  };

  const handelAuditCompanyLogoChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let tmpAuditCompany: auditCompanies = auditCompany;
    tmpAuditCompany.logoURL = e.target.value;
    setAuditCompany({ ...tmpAuditCompany });
  };

  const handelAuditCompanyRadioChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let tmpAuditCompany: auditCompanies = auditCompany;
    tmpAuditCompany.type =
      auditCompaniesType[e.target.value as keyof typeof auditCompaniesType];
    console.log(e.target.value);
    setAuditCompany({ ...tmpAuditCompany });
  };

  return (
    <>
      <div className={styles.container}>
        <h3>Add Audit Company</h3>
        <BasicInput
          title={"Name"}
          placeholder={"Sherlock"}
          onChange={handelAuditCompanyNameChange}
          redBorder={false}
        />
        <BasicInput
          title={"Website Link"}
          placeholder={"https://"}
          onChange={handelAuditCompanyURLChange}
          redBorder={false}
        />
        <BasicInput
          title={"Logo URL (Twitter)"}
          placeholder="https://"
          onChange={handelAuditCompanyLogoChange}
          redBorder={false}
        />

        <BasicRadioInput
          value={auditCompany.type}
          buttons={[
            {
              type: auditCompaniesType.ContestPlatform,
              title: "Contest platform",
              value: "ContestPlatform",
            },
            {
              type: auditCompaniesType.Institutional,
              value: "Institutional",
              title: "Institutional",
            },
            {
              type: auditCompaniesType.IndependentResearcher,
              value: "IndependentResearcher",
              title: "Independent Researcher",
            },
          ]}
          onChange={handelAuditCompanyRadioChange}
        />
        <button
          className={styles.saveButton}
          onClick={() => sendPublishReportDataRequest(auditCompany)}
        >
          Save audit company
        </button>
        <button
        className={styles.backbutton}
        onClick={() => setAuditCompanyIsActive(false)}
        >
        Back
      </button>
      </div>
    </>
  );
};
