import styles from "./SideMenu.module.css";
import { FaRegEye } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { protocolPreview } from "../../../utils/reportDataTypes";

interface ISideMenu {
  protocolPreview: protocolPreview;
  setAddProtocolActive: (addProtocolActive: boolean) => void;
}

const SideMenuPublishTool: React.FC<ISideMenu> = ({
  protocolPreview,
  setAddProtocolActive,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.sideMenu}>
        <ul className={styles.sideMenuList}>
          <div
            style={{ display: "flex", flexDirection: "row", padding: "10px 10px 10px 20px" }}
          >
            <img
              className={
                protocolPreview.logoURL.length > 0
                  ? styles.logo
                  : styles.logoTMP
              }
              alt=""
              src={
                protocolPreview.logoURL.length > 0
                  ? protocolPreview.logoURL
                  : "https://scauditstudio.de/wp-content/uploads/2024/04/eth.png"
              }
            />
            <b className={styles.headerText}>
              {protocolPreview.name.length > 0
                ? protocolPreview.name
                : "New Protocol"}
            </b>
          </div>
          <li className={styles.row}>
            <b>
              {protocolPreview.rid <= 0
                ? "Adding new Protocol"
                : "Currently Editing"}{" "}
            </b>
          </li>
          <li className={styles.row}>
            <b>
              {" "}
              Protocol ID:{" "}
              {protocolPreview.rid <= 0 ? "N/A" : protocolPreview.rid}
            </b>
          </li>
          <li className={styles.row}>
            <b> Completed: 50%</b>
          </li>
          <li className={styles.backButton} onClick={() => navigate("/")}>
            <FaRegEye />
            View Protocol
          </li>
          <li
            className={styles.backButton}
            onClick={() => setAddProtocolActive(false)}
          >
            <FaArrowLeft />
            Back
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideMenuPublishTool;
