import PurpleButton from "../../Reusable/PurpleButton";
import ProgressBar from "../ProgressBar";
import styles from "../RegisterPopUp.module.css";

interface IWalletConnectPage {
  onWalletRegisterHandler: () => void;
  handleShowWalletChange: () => void;
  progress: number;
  wallet: string;
}

const WalletConnectPage: React.FC<IWalletConnectPage> = ({
  progress,
  wallet,
  onWalletRegisterHandler,
  handleShowWalletChange,
}) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.loginTitle}>Connect Wallet</h3>
      <div className={styles.containerInner}>
        <div className={styles.description}>
          <p>
            Use Metamask to connect your wallet. You will have to sign a login
            message.{" "}
          </p>
        </div>

        <div className={styles.buttons}>
          <PurpleButton
            fixedsize={true}
            text="Connect Wallet"
            onClick={onWalletRegisterHandler}
          />
        </div>
      </div>
      <ProgressBar completed={progress} />
      <div className={styles.buttons}>
        <PurpleButton
          fixedsize={true}
          disabled={wallet.length < 2}
          text="Next"
          onClick={handleShowWalletChange}
        />
      </div>
    </div>
  );
};

export default WalletConnectPage;
