import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "reactflow/dist/style.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import PublicationsPage from "./pages/PublicationsPage";
import ProtocolPage from "./pages/ProtocolPage";
import PublishPage from "./pages/PublishPage";
import { useEffect, useState } from "react";
import { darkTheme, lightTheme } from "./utils/theme";
import AuditCompaniesPage from "./pages/AuditCompaniesPage";
import AllProtocolsPage from "./pages/AllProtocolsPage";
import UnsupportedScreen from "./pages/UnsupportedScreen";
import LoginPage from "./pages/LoginPage";
import ForgotPassword from "./pages/ForgotPassword";
import AccountPage from "./pages/AccountPage";
import { userData } from "./utils/severRequestTypes";
import { protocolPreview } from "./utils/reportDataTypes";

let isPageFirstLoad = true;

const App = () => {
  const [isDark, setIsDark] = useState(true);
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 600);
  const [user, setUser] = useState<userData | null>(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [protocolPreviews, setProtocolPreviews] = useState<protocolPreview[] | null>(null);
  const [currentProtocolID, setCurrentProtocolID] = useState(0);

  const theme = isDark ? darkTheme : lightTheme;
  useEffect(() => {
    if (isPageFirstLoad) {
      sessionStorage.clear();
    }
    isPageFirstLoad = false;
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  useEffect(() => {
    const handleResize = () => setIsMobileScreen(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "light" && isDark) {
      setIsDark(false);
    } else if (savedTheme === "dark" && !isDark) {
      setIsDark(true);
    } else {
      document.documentElement.style.setProperty(
        "--background-color",
        theme.sides
      );
      document.documentElement.style.setProperty("--font-color", theme.text);
      document.documentElement.style.setProperty(
        "--main-background",
        theme.main
      );
      document.documentElement.style.setProperty(
        "--sidemenu-font-color",
        theme.sideMenuFont
      );
      document.documentElement.style.setProperty(
        "--backgound-color-dark",
        theme.footerColor
      );
      document.documentElement.style.setProperty(
        "--ProtocolPage-text-color",
        theme.text
      );
      document.documentElement.style.setProperty(
        "--ProtocolPage-border-color",
        theme.text
      );
      document.documentElement.style.setProperty(
        "--lightborder-color",
        theme.text
      );
      document.documentElement.style.setProperty(
        "--side-menu-hover",
        theme.sideMenuHover
      );
      document.documentElement.style.setProperty(
        "--drop-down-menu",
        theme.dropDownMenuColor
      );
    }
  }, [theme, isDark]);

  return (
    <BrowserRouter>
      <div className="app">
        <Navbar user={user} />
        <div className="main">
          <Routes>
            <Route
              path="/"
              element={
                isMobileScreen ? (
                  <UnsupportedScreen />
                ) : (
                  <PublicationsPage
                    setCurrentProtocolID={setCurrentProtocolID}
                    currentTheme={theme}
                    setProtocolPreviews={setProtocolPreviews}
                    allprotocolsParam={protocolPreviews}
                  />
                )
              }
            />
            <Route
              path="/join-beta"
              element={
                isMobileScreen ? (
                  <UnsupportedScreen />
                ) : (
                  <LoginPage user={user} setUser={setUser} />
                )
              }
            />
            <Route
              path="/protocol/:title"
              element={
                isMobileScreen ? (
                  <UnsupportedScreen />
                ) : (
                  <ProtocolPage
                    currentProtocolID={currentProtocolID}
                    currentTheme={theme}
                    user={user}
                  />
                )
              }
            />
            <Route
              path="/publish"
              element={
                isMobileScreen ? (
                  <UnsupportedScreen />
                ) : (
                  <PublishPage setCurrentProtocolID={setCurrentProtocolID} user={user} />
                )
              }
            />
            <Route
              path="/auditCompanies"
              element={
                isMobileScreen ? <UnsupportedScreen /> : <AuditCompaniesPage />
              }
            />
            <Route
              path="/all-protocols"
              element={
                isMobileScreen ? (
                  <UnsupportedScreen />
                ) : (
                  <AllProtocolsPage
                    setCurrentProtocolID={setCurrentProtocolID}
                  />
                )
              }
            />
            <Route
              path="/account"
              element={
                isMobileScreen ? (
                  <UnsupportedScreen />
                ) : (
                  <AccountPage user={user} setUser={setUser} />
                )
              }
            />
            <Route
              path="/forgot"
              element={
                isMobileScreen ? <UnsupportedScreen /> : <ForgotPassword />
              }
            />
          </Routes>
        </div>
        <Footer isDark={isDark} setIsDark={setIsDark} />
        <ToastContainer />
      </div>
    </BrowserRouter>
  );
};

export default App;
