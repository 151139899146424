import styles from './LoginPage.module.css';
import { FaCheck } from "react-icons/fa";
import { useState } from 'react';
import RegisterPopUp from '../../components/RegisterPopUp';
import PurpleButton from '../../components/Reusable/PurpleButton';
import { Link } from 'react-router-dom';

interface ILoginPage{
    user:Object|null;
    setUser:(user:any)=>void
}

const LoginPage:React.FC<ILoginPage> = ({user,setUser}) => {
    const [showSignUp,setShowSignUp]=useState(false)
    const [isProSignUp,setIsProSignUp]=useState(false)

    const handleRegisterChange=(isProSignUp?: boolean)=>{
        setShowSignUp(prev=>!prev);
        if(isProSignUp !== undefined){
            setIsProSignUp(isProSignUp);
        }   
    }

    const features = [
        "Request up to 2 custom reports per month",
        "API access with up to 10.000 requests per month",
        "Create custom search querys",
        "Create custom graphs directly in SCAS",
        "Search 300+ codebases and 1000+ past audit reports"
    ];
    const featuresFree = [
        "Search all data on SCAS",
        "API access with up to 500 requests per month",
    ];

    if(showSignUp){
        return(
        <div className={styles.container}>
            <RegisterPopUp setUser={setUser} user={user} setIsRegisterPopUpVisible={() => handleRegisterChange(false)} isProSignUp={isProSignUp}/>
        </div>
        );
    }
    return (
        <div className={styles.payment}>
            <div className={styles.container}>
                <div className={styles.innerContainer}>
                    <div className={styles.getProContainer}>
                        <h3>Get SCAS Pro</h3>
                        <div className={styles.description}>
                        <p>Get SCAS Pro to access our codebase, access API and more</p>
                        </div>
                        <ul className={styles.featureList}>
                            {features.map((feature) => {return(
                                <li key={feature} className={styles.row}><FaCheck /> {feature}</li>
                            )}
                            )}
                        </ul>
                        <div className={styles.pricing}>
                                <p>- 499 USDC / user / 6 Month </p>
                        </div>
                        <div className={styles.buttonContainer}>
                            <PurpleButton text="Get SCAS Pro" onClick={() => handleRegisterChange(true)} fontSize='14px'/>
                        </div>
                        <p className={styles.greyText}>Already have an account? <a href='./account'>Login</a></p>
                    </div>
                    <div className={styles.getProContainer}>
                        <h3>Create a free SCAS account</h3>
                        <div className={styles.description}>
                        <p>Get a free SCAS account to get limited access to api</p>
                        </div>
                        <ul className={styles.featureList}>
                            {featuresFree.map((feature) => {return(
                                <li key={feature} className={styles.row}><FaCheck /> {feature}</li>
                            )}
                            )}
                        </ul>
                        <div className={styles.pricing}>
                                <p>- Contribute to SCAS to get 3 months SCAS Pro. Learn more</p>
                        </div>
                        <div className={styles.buttonContainer}>
                            <PurpleButton text="Create Account" onClick={() => handleRegisterChange(false)} fontSize='14px'/>
                        </div>
                        <p className={styles.greyText}>Already have an account? <Link to='../account'>Login</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage