import styles from "./PastAudits.module.css";
import {
  auditCompanies,
  codeComplexity,
  pastAudits,
  vulReport,
} from "../../../utils/reportDataTypes";
import { useEffect, useState } from "react";
import { BarChart } from "./Charts/BarChart";
import { DoughnutChart } from "./Charts/DoughnutChart";
import React from "react";
import ShowMoreButton from "../../Reusable/ShowMoreButton";
import { darkTheme } from "../../../utils/theme";
import { formatDate } from "../../../utils/textformUtils";
import { AuditReportsList } from "./AuditReportsList";
import { VulnerabilitiesList } from "./VulnerbilitesList";

interface IPastaudits {
  pastAudits: pastAudits;
  codeData: codeComplexity;
  auditCompanies: auditCompanies[];
  currentTheme?: typeof darkTheme;
}

const Pastaudits = React.forwardRef<HTMLDivElement, IPastaudits>(
  ({ codeData, pastAudits, currentTheme, auditCompanies }, ref) => {
    const [amountOfHighVulPerFile, setAmountOfHighVulPerFile] = useState<
      number[]
    >([]);
    const [amountOfMediumVulPerFile, setAmountOfMediumVulPerFile] = useState<
      number[]
    >([]);
    const [highIssues, setHighIssues] = useState(0);
    const [mediumIssues, setMediumIssues] = useState(0);
    const [lowIssues, setLowIssues] = useState(0);
    const [informationalIssues, setInformationalIssues] = useState(0);
    const [filesAffected, setFilesAffected] = useState<string[]>([]);
    const [showAllIssues, setShowAllIssues] = useState(false);
    const [showCodeLocations, setShowCodeLocations] = useState(false);
    const totalReports: vulReport[] = pastAudits.audits.flatMap(
      (audit) => audit.reports
    );

    useEffect(() => {
      const arr: string[] = [];

      for (let i = 0; i < totalReports.length; i++) {
        const regex = /main\/.+?\/(?<path>.+?)(?:#|$)/;
        const match = totalReports[i].file.match(regex);
        if (match && match.groups) {
          const path = match.groups.path;
          totalReports[i].file = path;
        }
        if (!arr.includes(totalReports[i].file)) {
          arr.push(totalReports[i].file);
        }
      }

      const amountOfHighVulPerFile: number[] = new Array<number>(arr.length);
      const amountOfMediumVulPerFile: number[] = new Array<number>(arr.length);
      let tmpMedIssue = 0;
      let tmpHighIssue = 0;
      let tmpLowIssue = 0;
      let tmpInfoIssue = 0;
      //This might not be the optimal solution
      for (let i = 0; i < totalReports.length; i++) {
        if (totalReports[i].severity.toLowerCase() === "medium") {
          if (amountOfMediumVulPerFile[arr.indexOf(totalReports[i].file)] > 0) {
            amountOfMediumVulPerFile[arr.indexOf(totalReports[i].file)]++;
          } else {
            amountOfMediumVulPerFile[arr.indexOf(totalReports[i].file)] = 1;
          }
          tmpMedIssue++;
        } else if (totalReports[i].severity.toLowerCase() === "high") {
          if (amountOfHighVulPerFile[arr.indexOf(totalReports[i].file)] > 0) {
            amountOfHighVulPerFile[arr.indexOf(totalReports[i].file)]++;
          } else {
            amountOfHighVulPerFile[arr.indexOf(totalReports[i].file)] = 1;
          }
          tmpHighIssue++;
        }
        if (totalReports[i].severity.toLowerCase() === "low") {
          tmpLowIssue++;
        }
        if (totalReports[i].severity.toLowerCase() === "info" || totalReports[i].severity.toLowerCase() === "low/info") {
          tmpInfoIssue++;
        }
      }

      setHighIssues(tmpHighIssue);
      setMediumIssues(tmpMedIssue);
      setLowIssues(tmpLowIssue);
      setInformationalIssues(tmpInfoIssue);

      setAmountOfHighVulPerFile(amountOfHighVulPerFile);
      setAmountOfMediumVulPerFile(amountOfMediumVulPerFile);
      setFilesAffected(arr);
    }, [pastAudits]);

    return (
      <>
        <div id="pastAuditContainer" className={styles.container} ref={ref}>
          <h2>Past Audits</h2>
          <div className={styles.InnerContainer}>
            <div className={styles.chartContainer}>
              <DoughnutChart
                currentTheme={currentTheme}
                highIssues={highIssues}
                mediumIssues={mediumIssues}
                lowIssues={lowIssues}
                informationalIssues={informationalIssues}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div className={styles.InfoContainer}>
                <div className={styles.InfoContainerBox}>
                  <p>Number of Audits </p>
                  <h2>{pastAudits.auditAmount}</h2>
                </div>
                <div className={styles.InfoContainerBox}>
                  <p>Number of Vul. Found </p>
                  <h2>{pastAudits.reportAmount}</h2>
                </div>
                <div className={styles.InfoContainerBox}>
                  <p>Date of Last Audit </p>
                  <h2>
                    {pastAudits.audits.length > 0
                      ? formatDate(pastAudits.audits[0].date)
                      : ""}
                  </h2>
                </div>
              </div>
              <AuditReportsList codeData={codeData} auditCompanies={auditCompanies} pastAudits={pastAudits}/>
            </div>
          </div>
          <ShowMoreButton
            onClick={() => setShowCodeLocations(!showCodeLocations)}
            text={"Show Code Locations"}
            showMore={showCodeLocations}
          />
          <div className={styles.chartContainerFull} style={{ height: showCodeLocations ? "20rem" : "0rem" }}>
              <BarChart
                currentTheme={currentTheme}
                labels={filesAffected}
                AmountOfHighVulPerFile={amountOfHighVulPerFile}
                AmountOfMediumVulPerFile={amountOfMediumVulPerFile}
              />
          </div>
          <ShowMoreButton
            onClick={() => setShowAllIssues(!showAllIssues)}
            text={"Show all Vulnerabilities"}
            showMore={showAllIssues}
          />
          {totalReports.length > 0 &&(
            <div className={styles.vulReportContainer} style={{ maxHeight: showAllIssues ? "250px" : "0px" }}>
              <VulnerabilitiesList totalReports={totalReports}/>
            </div>
          )}
        </div>
      </>
    );
  }
);

export default Pastaudits;
