import styles from './PurpleButton.module.css'

interface IPurpleButton{
    text: string;
    onClick?: () => void;
    fontSize?:string;
    type?: "submit";
    disabled?: boolean;
    fixedsize?: boolean;
    width?: string;
    height?: string;
}

const PurpleButton:React.FC<IPurpleButton>=({text,onClick,fontSize,type,disabled,fixedsize,width,height})=>{
    let myType:"button"|"submit"=type?type:"button"

    if(onClick){
        return <button 
        type={myType} 
        disabled={disabled !== undefined ? disabled : false} 
        style={{fontSize:fontSize ? fontSize : "16px", width:width ? width : "",height:height ? height: ""}} 
        onClick={onClick}
        className={fixedsize ? styles.buttonFixed : styles.button}>
            {text}
        </button>
    }
    else{
        return <button 
        type={myType} 
        style={fontSize?{fontSize:fontSize}:{fontSize:"16px"}} 
        className={fixedsize ? styles.buttonFixed : styles.button}>
            {text}
        </button>
    }


}

export default PurpleButton