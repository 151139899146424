import React from 'react';
import styles from './RegisterPopUp.module.css';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import PurpleButton from '../Reusable/PurpleButton';
import ProgressBar from './ProgressBar';
import {ethers, Contract} from "ethers";
import { toast } from 'react-toastify';
import WalletConnectPage from './WalletConnectPage';
import PaymentPage from './PaymentPage';
import ConfirmationCodePage from './ConfirmationCodePage';
import { isPasswordValid, isValidEmail } from '../../utils/textformUtils';
import { registerUserURL } from '../../api';
import { sendRequest } from '../../utils/apiRequest';

const usdc = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";
const to = "";

interface IRegisterPopUp {
    setIsRegisterPopUpVisible: (bool: boolean) => void;
    isProSignUp: boolean;
    user:Object|null;
    setUser:(user:any)=>void
}

const RegisterPopUp: React.FC<IRegisterPopUp> = ({setIsRegisterPopUpVisible,isProSignUp}) => {
    const [email,setEmail]=useState<string>("")
    const [password,setPassword]=useState<string>("")
    const [password2,setPassword2]=useState<string>("")
    const [emailError,setEmailError]=useState<boolean>(false)
    const [wallet,setWallet]=useState<string>("")
    const [passwordError,setPasswordError]=useState<boolean>(false)
    const [passwordMatchError,setPasswordMatchError]=useState<boolean>(false)

    const [isRegistered,setIsRegistered]=useState<boolean>(false)
    const [registerPending,setRegisterPending]=useState<boolean>(false)
    const [showWalletConnect,setshowWalletConnect]=useState<boolean>(true)
    const [showPayment,setShowPayment]=useState<boolean>(false)

    const [progress,setProgress] =useState<number>(0)
    const maxProgress = isProSignUp ? 4 : 3;

    async function handleShowWalletChange() {
        setRegisterPending(true)
        const request = {email:email,pw:password,wallet:wallet}
        const response = await sendRequest(registerUserURL,"Error!",undefined,request);
        setRegisterPending(false)
        if(!response) return;
        setshowWalletConnect(false)
        const progress = (2 / maxProgress) * 100;
        setProgress(progress)
    }

    async function handelShowFinish() {
        if(isProSignUp && !showPayment){
            setShowPayment(true);
            setshowWalletConnect(false);
            const progress = (3 / maxProgress) * 100;
            setProgress(progress)
            return;
        }
        if(isProSignUp){
            await onBuyHandler("499",1);
        }
        const progress = (maxProgress / maxProgress) * 100;
        setProgress(progress)
        setTimeout(()=>{
                setIsRegisterPopUpVisible(false)
        },500)
        setshowWalletConnect(false)
    }

    const handleEmailChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setEmailError(!isValidEmail(e.target.value))
        setEmail(e.target.value)
    }

    const handlePasswordChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setPasswordMatchError(e.target.value !== password2)
        setPasswordError(!isPasswordValid(e.target.value))
        setPassword(e.target.value)
    }

    const handlePassword2Change=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setPasswordMatchError(e.target.value !== password)
        setPassword2(e.target.value)
    }

    const handleSubmit=()=>{
        if(password !== "" && password2 !== "" && !passwordError && email!=="" && !emailError && !passwordMatchError){
            setIsRegistered(true)
            setTimeout(()=>{
                setRegisterPending(false)
            },1000)
            const progress = (1 / maxProgress) * 100;
            setProgress(progress)
        }
    }

    const onWalletRegisterHandler = async () => {
        console.log("register")
        let provider, signer
        // @ts-ignore
        if (window.ethereum == null) {
            console.log("no metamask found")
        } else {
            try {
                // @ts-ignore
                provider = new ethers.BrowserProvider(window.ethereum)
                console.log("register1")
                signer = await provider.getSigner()
                const message:string = "Login";
                console.log("register2")
                const signature = await signer.signMessage(message);

                const recoveredAddress = ethers.verifyMessage(message, signature);
                if(recoveredAddress === await signer.getAddress()){
                    setWallet(recoveredAddress);
                    toast.success("Sign Up")
                }else{
                    toast.error("Something went wrong!")
                }
                
            } catch (error) {   
                toast.error("Something went wrong!")
                console.log("error:", error)
            }
        }
    }

    const onBuyHandler = async (price: string,productID: number) => {
        let provider, signer
        // @ts-ignore
        if (window.ethereum == null) {
            console.log("no metamask found")
        } else {
            try {
                // @ts-ignore
                provider = new ethers.BrowserProvider(window.ethereum)
                signer = await provider.getSigner()
                const { chainId } = await provider.getNetwork()
                if(chainId !== BigInt(137)){
                    toast.error("Something went wrong!");
                    return;
                }
                const abi = [
                    "function transfer(address to, uint amount) external returns(bool)"
                ]
                  
                const contract = new Contract(usdc, abi, signer)
                
                const usdcAmount = price;

                const transaction = await contract.transfer(to, usdcAmount);
                // Wait for the transaction to be mined
                const receipt = await transaction.wait();
        
                // Get the transaction hash
                const txHash = receipt.transactionHash;
                console.log("Transaction succsessfull" + txHash);
                //await sendPaymentStartRequest("test555",String(signer.address),Number(usdcAmount),productID);
                toast.success("Thank you for your purchase!")
            } catch (error) {   
                toast.error("Something went wrong!")
                console.log("error:", error)
            }
        }
    }

    if (isRegistered){
        if(registerPending){
            return(
                <div className={styles.container}>
                    <Box sx={{ display: 'flex' ,alignItems:"center",verticalAlign:"center",justifyContent:"center",height:"100%",width:"100%"}} >
                        <CircularProgress className={styles.circle}/>
                    </Box>
                </div>)
        }
        if(showPayment){
            return(
                <PaymentPage wallet={wallet} progress={progress} handelShowFinish={handelShowFinish}/>
            )
        }
        if(showWalletConnect){
            return(
                <WalletConnectPage wallet={wallet} progress={progress} handleShowWalletChange={handleShowWalletChange} onWalletRegisterHandler={onWalletRegisterHandler}/>
            )
        }
        return(
            <ConfirmationCodePage progress={progress} email={email} isProSignUp={isProSignUp} handelShowFinish={handelShowFinish}/>
        )
    }
    return (
        <div className={styles.container}>
            { isProSignUp ?
            <h3 className={styles.loginTitle}>Sign Up for 6 Months SCAS Pro </h3> :
            <h3 className={styles.loginTitle}>Create Account</h3>
            }
            <div className={styles.containerInner}>
                <div className={styles.inputGroup}>
                    <label className={styles.loginLable}>Email:</label>
                    <input value={email} onChange={handleEmailChange} className={styles.loginInput} type="text" id="email" name="email" />
                    {emailError && <span className={styles.errorMsg}>Invalid email</span>}
                </div>
                <div className={styles.inputGroup}>
                    <label className={styles.loginLable}>Password:</label>
                    <input value={password} onChange={handlePasswordChange} className={styles.loginInput} type="password" id="password" name="password" />
                </div>
                <div className={styles.inputGroup}>
                    <label className={styles.loginLable}>Repeat password:</label>
                    <input value={password2} onChange={handlePassword2Change} className={styles.loginInput} type="password" id="password" name="password" />
                    {(passwordError || passwordMatchError) &&
                        passwordError
                        ?<span className={styles.errorMsg}>Password can only contain letters, numbers and symbols, needs to be 8 characters long and include an uppercase letter</span>
                        :passwordMatchError &&<span className={styles.errorMsg}>Passwords do not match</span>
                    }
                </div>
            </div>
            <ProgressBar completed={progress}/>
                <div className={styles.buttons}>
                    <PurpleButton fixedsize={true} text="Next" onClick={handleSubmit}/>
                </div>
        </div>
    );
}

export default RegisterPopUp;