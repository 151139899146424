import { useState} from 'react'
import React from 'react'
import styles from './Login.module.css'
import axios from 'axios'
import { loginAdminURL } from '../../../api'
import { adminLoginData } from '../../../utils/severRequestTypes'
import { toast } from 'react-toastify'

interface ILogin{
    setLoggedIn:(loggedIn:boolean)=>void
    setLoginData:(loginData:adminLoginData)=>void
}

export const Login:React.FC<ILogin>=({setLoggedIn,setLoginData})=>{
    const [password,setPassword]=useState("");
    const [userName,setUserName]=useState("");

    const handlePasswordChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setPassword(e.target.value);
    }
    const handleUserNameChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setUserName(e.target.value)
    }
    
    const sendLoginRequest = async () => {
        const request:adminLoginData = {id:0,name:userName,password:password};

        try {
          const {data} = await axios.post(loginAdminURL, 
            request,
            {
              headers: {
              'Content-Type': 'application/json',
              }
            }
          );
          if(data === "True"){
            console.log(data);
            setLoginData({id:0,name:userName,password:password});
            setLoggedIn(true);
          }
          
          //
        }catch(e){
          console.log(e);
          toast.error("Something went wrong!")
          // Show error
          //setMessage({message: "No Connection to server"});
        }
    }

    return(
        <div className={styles.container}>
            <div className={styles.loginContainer}>
                <div>Admin login</div>
                <p>User Name</p>
                <input value={userName} onChange={handleUserNameChange}/>
                <p>Password</p>
                <input type="password" value={password} onChange={handlePasswordChange}/>
                <button onClick={sendLoginRequest}>Submit</button>
            </div>
        </div>
    )
}