import ReactFlow, { Controls } from 'reactflow';
import 'reactflow/dist/style.css';
import { contractMetric } from '../../../../utils/reportDataTypes';

/*const initialEdges = [
    { id: 'e1-2', source: '1', target: '2' },
    { id: 'e1-3', source: '1', target: '3' },
    { id: 'e1-4', source: '4', target: '2' },
  ];*/

const proOptions = { hideAttribution: true };

interface IFlow {
    contracts: contractMetric[],
}
type node = {
  id: string,
  data: {label:string},
  position: {x: number, y: number},
}
type edge = {
  id: string,
  source: string,
  target: string,
}

const Flow:React.FC<IFlow>=({contracts}) => {

  const nodes : node[] = []; 

  for (let i = 0; i < contracts.length; i++) {
    const fileName = contracts[i].fileName
    if(fileName.includes("test") || fileName.includes("Test") || fileName.includes("Mock")){
      continue;
    }
    nodes.push({
      id: i.toString(),
      data:{label:fileName.split(".")[0]},
      position:{x:i*160,y:0}
    });
  }

  const edges : edge[] = [];

  for (let i = 0; i < contracts.length; i++){
    let mult = 1;
    for(let j = 0; j < nodes.length; j++){
      if(contracts[i].inherits.includes(nodes[j].data.label)){

        const pos1 = nodes.find(n => n.data.label === contracts[i].fileName.split(".")[0])?.id ?? "-1";
        const pos2 = nodes[j].id;
        const nodeChild = nodes.find(n => n.data.label === contracts[i].fileName.split(".")[0]) ?? nodes[j];

        if(pos1 === "-1"){
          continue;
        }

        if(nodes[j].position.y === 0){
          nodes[j].position.y = nodeChild.position.y + (150);
          nodes[j].position.x = nodeChild.position.x + (160 * mult);
        }

        mult++;

        const id = `e${pos1}-${pos2}`;
        if(edges.find(e => e.id === id)){
          continue;
        }
        edges.push({
          id:id,
          source: pos1,
          target: pos2,
        });
      }
    }
  }
  let currentXpos = 0;
  for(let i = 0; i < nodes.length; i++){
    if(nodes[i].position.y === 0){
      nodes[i].position.x = currentXpos;
      currentXpos += 160;
    }
  }

  console.log(contracts);
  return (
        <div style={{ height: '100%', width: '100%' }}>
        <ReactFlow nodes={nodes} edges={edges} proOptions={proOptions} fitView={true}>
            <Controls />
        </ReactFlow>
    </div>    
  );
}

export default Flow;
