import { useEffect, useState } from "react";
import { protocolDataCategory } from "../../../utils/protocolDataCategoryTypes";
import styles from "./BasicInfo.module.css";
import {
  isValidURL,
  checkIfValid,
  isValidLogoURL,
} from "../../../utils/adminUtils";
import BasicInput from "../../Reusable/BasicInput";
import BasicList from "../../Reusable/BasicList";
import BasicSelect from "../../Reusable/BasicSelect";
import { getAllBlockchainsURL, writeBasicDataURL } from "../../../api";
import {
  adminLoginData,
  basicData,
  basicRequestDataSubmittRequest,
} from "../../../utils/severRequestTypes";
import { blockchain } from "../../../utils/reportDataTypes";
import { sendRequest } from "../../../utils/apiRequest";
import BasicTextArea from "../../Reusable/BasicTextArea";
import PurpleButton from "../../Reusable/PurpleButton";

type protocolDataCategoryObject = {
  name: string;
  id: number;
};
interface IBasicInfo {
  adminLoginData: adminLoginData;
  protocolId: number;
  setProtocolID: (newP: number) => void;
  setProgress: (newA: number[]) => void;
}
export const BasicInfo: React.FC<IBasicInfo> = ({
  protocolId,
  adminLoginData,
  setProtocolID,
  setProgress,
}) => {
  const [logoUrlIsValid, setLogoUrlIsValid] = useState<boolean>(false);
  const [webURLIsValid, setWebURLIsValid] = useState<boolean>(false);
  const [blockchains, setBlockchains] = useState<blockchain[]>([]);
  const [progress, setProgressLocal] = useState<number[]>([0, 0, 0, 0, 0, 0]);
  const [basicDataRequest, setBasicDataRequest] = useState<basicData>({
    protocolDataId: protocolId === -1 ? 0 : protocolId,
    name: "",
    websiteURL: "",
    deployedOnBlockchain: [],
    logoURL: "",
    category: protocolDataCategory.Other,
    recommendation: "",
  });

  const protocolDataCategoryArrayString: string[] = Object.keys(
    protocolDataCategory
  ).filter((key) => isNaN(Number(key)));

  const protocolDataCategoryArray: protocolDataCategoryObject[] = [];

  protocolDataCategoryArrayString.map((value, index) => {
    return protocolDataCategoryArray.push({ name: value, id: index });
  });

  useEffect(() => {
    const fetchData = async () => {
      await sendGetAllAuditCompaniesRequest();
    };
    fetchData();
  }, []);

  const setProgressHelper = (n: number) => {
    if (progress[n] < 1) {
      const arr = progress;
      arr[n] = 1;
      setProgressLocal(arr);
      setProgress([arr.filter((num) => num === 1).length, 6]);
    }
  };

  const sendGetAllAuditCompaniesRequest = async () => {
    sendRequest(
      getAllBlockchainsURL,
      "Error getting audit companies",
      undefined,
      undefined,
      setBlockchains
    );
  };

  const sendPublishReportDataRequest = async (data: basicData) => {
    const request: basicRequestDataSubmittRequest = {
      loginData: adminLoginData,
      basicData: data,
    };
    console.log(JSON.stringify(request));

    sendRequest(
      writeBasicDataURL,
      "Something went wrong!",
      "Data added!",
      request,
      setProtocolID
    );
  };

  const handleAddDeployedOn = () => {
    const updatedObj = {
      ...basicDataRequest,
      deployedOnBlockchain: basicDataRequest.deployedOnBlockchain.concat([0]),
    };
    setBasicDataRequest(updatedObj);
    setProgressHelper(0);
  };

  const handleDeployedOnChange =
    (index: number) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      basicDataRequest.deployedOnBlockchain[index] = Number(event.target.value);
      setBasicDataRequest({ ...basicDataRequest });
    };

  const handleRemoveDeployedOn = (index: number) => {
    delete basicDataRequest.deployedOnBlockchain[index];
    setBasicDataRequest({ ...basicDataRequest });
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let copy = { ...basicDataRequest };
    copy.name = e.target.value;
    setBasicDataRequest(copy);
    setProgressHelper(1);
  };

  const handleLogoUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidLogoURL(e.target.value) || protocolId > 0) {
      setLogoUrlIsValid(true);
    } else {
      setLogoUrlIsValid(false);
    }

    let copy = { ...basicDataRequest };
    copy.logoURL = e.target.value;
    setBasicDataRequest(copy);
    setProgressHelper(2);
  };

  const handleWebUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidURL(e.target.value) || protocolId > 0) {
      setWebURLIsValid(true);
    } else {
      setWebURLIsValid(false);
    }

    let copy = { ...basicDataRequest };
    copy.websiteURL = e.target.value;
    setBasicDataRequest(copy);

    setProgressHelper(3);
  };

  const handleRecommendationsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    let copy = { ...basicDataRequest };
    copy.recommendation = e.target.value;
    setBasicDataRequest(copy);

    setProgressHelper(4);
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    basicDataRequest.category = Number(event.target.value);
    setBasicDataRequest({ ...basicDataRequest });

    setProgressHelper(5);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <BasicInput
            title={"Name"}
            value={basicDataRequest.name}
            placeholder={"Uniswap"}
            onChange={handleNameChange}
            redBorder={false}
          />
          <BasicInput
            title={"Logo Url"}
            value={basicDataRequest.logoURL}
            placeholder={"www."}
            onChange={handleLogoUrlChange}
            redBorder={!logoUrlIsValid}
          />
          <BasicInput
            title={"Website Url"}
            value={basicDataRequest.websiteURL}
            placeholder={"www."}
            onChange={handleWebUrlChange}
            redBorder={!webURLIsValid}
          />
          <BasicTextArea
            title={"Recomendation on how to avoid risk"}
            value={basicDataRequest.recommendation}
            placeholder={"Use xyz...."}
            onChange={handleRecommendationsChange}
            redBorder={false}
          />
        </div>
        <div className={styles.containerInner}>
          <BasicList
            gap={0}
            title="Deployed on"
            addButtonText="Blockchain"
            handleRemove={handleRemoveDeployedOn}
            handleAdd={handleAddDeployedOn}
            height={170}
            list={basicDataRequest.deployedOnBlockchain}
            items={[
              {
                type: "select",
                onChange: handleDeployedOnChange,
                title: "",
                value: "chainId",
                display: "name",
                options: blockchains,
              },
            ]}
          />
          <BasicSelect
            title="Protocol Category"
            curValue={basicDataRequest.category.toString()}
            onChange={handleCategoryChange}
            value="id"
            display="name"
            options={protocolDataCategoryArray}
          />
        </div>
      </div>
      <PurpleButton
        width="90%"
        height="3rem"
        text="Upload Basic Data"
        onClick={() => sendPublishReportDataRequest(basicDataRequest)}
        disabled={!checkIfValid([webURLIsValid, logoUrlIsValid])}
      />
    </>
  );
};

export default BasicInfo;
