import styles from "./Centralization.module.css";
import { useState } from "react";
import BasicInput from "../../Reusable/BasicInput";
import {
  adminLoginData,
  centralizationDataSubmittRequest,
} from "../../../utils/severRequestTypes";
import BasicSelect from "../../Reusable/BasicSelect";
import { writeCentralizationDataURL } from "../../../api";
import { sendRequest } from "../../../utils/apiRequest";
import PurpleButton from "../../Reusable/PurpleButton";

interface ICentralization {
  adminLoginData: adminLoginData;
  protocolId: number;
  setProgress: (newA: number[]) => void;
}

const Centralization: React.FC<ICentralization> = ({
  protocolId,
  adminLoginData,
  setProgress
}) => {
  const [timeLockInformation, setTimeLockInformation] = useState<string>("");
  const [adminWallet, setAdminWallet] = useState<string>("");
  const [adminInformation, setAdminInformation] = useState<string>("");
  const [pauseInformation, setPauseInformation] = useState<string>("");
  const [frontendInformation, setFrontendInformation] = useState<string>("");
  const [progress,setProgressLocal] = useState<number[]>([0,0,0,0,0]);
  const [ownFrontendInformation, setOwnFrontendInformation] =
    useState<string>("");
  const [offChainMaintanceScore, setOffChainMaintanceScore] =
    useState<number>(1);

  const sendPublishReportDataRequest = async () => {
    const request: centralizationDataSubmittRequest = {
      admin: adminLoginData,
      reportID: protocolId,
      adminWallet: adminWallet,
      timeLockInformation: timeLockInformation,
      adminInformation: adminInformation,
      pausableInformation: pauseInformation,
      frontendDeployment: frontendInformation,
      possibilityOfOwnFrontend: ownFrontendInformation,
      ownFrontendComplexityScore: offChainMaintanceScore,
    };
    sendRequest(
      writeCentralizationDataURL,
      "Something went wrong while uploading data!",
      "Centralization added!",
      request
    );
  };

  const setProgressHelper = (n:number) => {
    if(progress[n] < 1){
      const arr = progress;
      arr[n] = 1;
      setProgressLocal(arr);
      setProgress([arr.filter(num => num === 1).length,5]);
    }
  }

  const handleTimeLockChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeLockInformation(e.target.value);
    
  };
  const handelAdminWalletChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdminWallet(e.target.value);
    setProgressHelper(1);
  };
  const handelAdminInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdminInformation(e.target.value);
    setProgressHelper(2);
  };
  const handelPauseInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPauseInformation(e.target.value);
    setProgressHelper(0);
  };
  const handelFrontendInformationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFrontendInformation(e.target.value);
    setProgressHelper(3);
  };
  const handelOwnFrontendInformationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOwnFrontendInformation(e.target.value);
    setProgressHelper(4);
  };

  const handelOffChainScoreChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = Number(e.target.value);
    setOffChainMaintanceScore(value);
  };

  const selectValues = [
    { name: "Exelent (1)", id: 1 },
    { name: "Avg (2)", id: 2 },
    { name: "Poor (3)", id: 3 },
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <BasicInput
            title="Information about Timelocks"
            value={timeLockInformation}
            redBorder={false}
            placeholder="Timelock on Stake..."
            onChange={handleTimeLockChange}
          />
          <BasicInput
            title="Admin wallet"
            value={adminWallet}
            redBorder={false}
            placeholder="0x..."
            onChange={handelAdminWalletChange}
          />
          <BasicInput
            title="Information about Admin"
            value={adminInformation}
            redBorder={false}
            placeholder="Admin can ..."
            onChange={handelAdminInfoChange}
          />
          <BasicInput
            title="Information about Pause"
            value={pauseInformation}
            redBorder={false}
            placeholder="Can be paused.."
            onChange={handelPauseInfoChange}
          />
        </div>
        <div className={styles.containerInner}>
          <BasicInput
            title="Information on Frontend"
            value={frontendInformation}
            redBorder={false}
            placeholder="XYZ has own frontend..."
            onChange={handelFrontendInformationChange}
          />
          <BasicInput
            title="Information about own Frontend"
            value={ownFrontendInformation}
            redBorder={false}
            placeholder="Front end is possible..."
            onChange={handelOwnFrontendInformationChange}
          />
          <BasicSelect
            title="How easy is it to maintain own Frontend?"
            curValue={offChainMaintanceScore.toString()}
            onChange={handelOffChainScoreChange}
            value="id"
            display="name"
            options={selectValues}
          />
        </div>
      </div>
      <PurpleButton
        width="90%"
        height="3rem"
        text="Upload Centralizaion Data"
        onClick={sendPublishReportDataRequest}
        disabled={protocolId <= 0}
      />
    </>
  );
};

export default Centralization;
