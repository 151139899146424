

export function setColorFromSecScore(percentage: number, color1: string, color2: string, color3: string) {
    if (percentage < 0 || percentage > 100) {
        throw new Error('Percentage must be between 0 and 100');
    }

    const parseColor = (colorStr: string) => {
        const color = colorStr.startsWith('#') ? colorStr.substring(1) : colorStr;
        const r = parseInt(color.substring(0, 2), 16);
        const g = parseInt(color.substring(2, 4), 16);
        const b = parseInt(color.substring(4, 6), 16);
        return { r, g, b };
    };

    const color1RGB = parseColor(color1);
    const color2RGB = parseColor(color2);
    const color3RGB = parseColor(color3);

    const lerp = (start: number, end: number, amt: number) => Math.round(start + (end - start) * amt);
    
    let r, g, b;
    
    if (percentage <= 50) {
        // Interpolate between color1 and color2
        const lerpPercentage = percentage / 50; // Normalized between 0 and 1
        r = lerp(color1RGB.r, color2RGB.r, lerpPercentage);
        g = lerp(color1RGB.g, color2RGB.g, lerpPercentage);
        b = lerp(color1RGB.b, color2RGB.b, lerpPercentage);
    } else {
        // Interpolate between color2 and color3
        const lerpPercentage = (percentage - 50) / 50; // Normalized between 0 and 1
        r = lerp(color2RGB.r, color3RGB.r, lerpPercentage);
        g = lerp(color2RGB.g, color3RGB.g, lerpPercentage);
        b = lerp(color2RGB.b, color3RGB.b, lerpPercentage);
    }

    const toHex = (value: number) => value.toString(16).padStart(2, '0');

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
